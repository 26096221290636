<template>
    <div>
        <ion-input type="text" placeholder="Username" clearInput class="ion-margin-top ion-margin-bottom custom"
            :value="username" :clear-input="false" @input="updateUsername($event.target.value)"
            maxlength="12"></ion-input>
        <ion-text class="ion-padding-bottom"><sub>{{
            $t('dashboard.account.userInfo.username.username_input.status_message')
                }}</sub></ion-text><br>
        <ion-text expand="" color="primary" @click="genUsername()" class="ion-padding-bottom"><sub>{{
            $t('dashboard.account.userInfo.username.username_input.generate_username') }}</sub></ion-text>
    </div>
</template>

<script>
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';
import { generateUsername } from '../../utils/utils';
import debounce from 'lodash/debounce';

export default {
    props: ['username'],
    emits: ['update:username', 'update:isUsernameTaken'],
    setup() {
        return {
            generateUsername
        };
    },
    data() {
        return {
            isUsernameTaken: false,
            usernameStatusMessage: 'Begin typing to check username availability.',
            matcher: new RegExpMatcher({
                ...englishDataset.build(),
                ...englishRecommendedTransformers
            })
        }
    },
    created() {
        this.debouncedCheckUsername = debounce(this.checkUsername, 500);
    },
    methods: {
        genUsername() {
            this.updateUsername(this.generateUsername());
        },
        updateUsername(username) {
            this.$emit('update:username', username);
        },
        async checkUsername() {
            let errorMessage = '';
            if (this.username.includes(' ')) {
                errorMessage = 'Username cannot contain spaces.';
            } else if (this.username.length < 3) {
                errorMessage = 'Username must be at least 3 characters long.';
            } else if (this.isProfane(this.username)) {
                errorMessage = 'Username contains inappropriate language.';
            }

            if (errorMessage) {
                this.updateUsernameStatus(true, errorMessage);
                return;
            }

            try {
                const response = await fetch(`${this.$config.public.API_BASE_URL}user/check_username/?username=${encodeURIComponent(this.username.toLowerCase())}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const result = await response.json();
                const message = result.isTaken ? 'This username is already taken.' : 'This username is available.';
                this.updateUsernameStatus(result.isTaken, message);
            } catch (error) {
                console.error('Error checking username:', error);
                this.updateUsernameStatus(true, 'Error checking username. Please try again.');
            }
        },
        updateUsernameStatus(isTaken, message) {
            this.isUsernameTaken = isTaken;
            this.usernameStatusMessage = message;
            this.$emit('update:isUsernameTaken', this.isUsernameTaken);
        },
        isProfane(username) {
            return this.matcher.hasMatch(username);
        }
    },
    watch: {
        username() {
            this.debouncedCheckUsername();
        }
    }
};
</script>